function getCookie(name) {
    var cookieValue = null
    if (document.cookie && document.cookie !== "") {
        var cookies = document.cookie.split(";")
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i])
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1)
                )
                break
            }
        }
    }
    return cookieValue
}

let csrftoken = getCookie("csrftoken")

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method)
}

$.ajaxSetup({
    beforeSend: function (xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken)
        }
    },
})

$(".clickable").on("click", function () {
    window.location.href = $(this).data("url")
})

function initialiseDatepickers() {
    $(".datepicker")
        .datepicker({
            format: "dd/mm/yyyy",
            autoclose: true,
            calendarWeeks: true,
            clearBtn: true,
            todayHighlight: true,
            weekStart: "1",
        })
        .attr("autocomplete", "off")
}

$(document).ready(function () {
    initialiseDatepickers()
})
