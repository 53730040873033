// Initialise Sentry first in order to catch any errors including those from loading the rest of the JS
import "@sentry/browser"
const SENTRY_DSN = JSON.parse(document.querySelector("#SENTRY_DSN").textContent)
if (SENTRY_DSN) {
    // Check that Sentry has been loaded (it could have been blocked by an Adblocker)
    const SentryLoaded = typeof Sentry !== "undefined"
    // Ignore common errors not caused by our application eg. browser extensions
    // Adapted from https://gist.github.com/impressiver/5092952
    const username = JSON.parse(document.querySelector("#username").textContent)
    if (SentryLoaded) {
        Sentry.init({
            dsn: SENTRY_DSN,
            ignoreErrors: [
                // Random plugins/extensions
                "top.GLOBALS",
                "canvas.contentDocument",
                "atomicFindClose",
                // ISP "optimizing" proxy
                "bmi_SafeAddOnload",
                "EBCallBackMessageReceived",
                "conduitPage",
            ],
            ignoreUrls: [
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                // Firefox extensions
                /^resource:\/\//i,
                // Local development
                /https?:\/\/127\.0\.0\.1/i,
                /https?:\/\/localhost/gi,
                // Google Translate
                /https?:\/\/translate\.googleusercontent\.com/gi,
            ],
        })
        Sentry.configureScope((scope) => {
            scope.setUser({
                username: username,
            })
        })
    }
}

const HOTJAR_SITE_ID = JSON.parse(
    document.querySelector("#HOTJAR_SITE_ID").textContent
)
if (HOTJAR_SITE_ID) {
    ;(function (h, o, t, j, a, r) {
        h.hj =
            h.hj ||
            function () {
                ;(h.hj.q = h.hj.q || []).push(arguments)
            }
        h._hjSettings = { hjid: HOTJAR_SITE_ID, hjsv: 6 }
        a = o.getElementsByTagName("head")[0]
        r = o.createElement("script")
        r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")
}

// Import all JavaScript libraries used anywhere in the codebase, so that Webpack will
// create a bundle containing all these dependencies
import "jquery"
import "popper.js"
import "bootstrap"
import "datatables.net"
import "datatables.net-bs4"
// Remove and uninstall unused datatables plugins
import "datatables.net-fixedcolumns-bs4"
import "datatables.net-fixedheader-bs4"
import "datatables.net-responsive"
import "datatables.net-responsive-bs4"
import "datatables.net-rowgroup-bs4"
import "datatables.net-scroller-bs4"
import "moment"
import "bootstrap-datepicker"
import "sweetalert2"
import "select2"
import "django-select2"
import "htmx.org"

import "./utils.js"
